<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        You are provided with a vial of an hypothetical solid that is non-volatile and a
        non-electrolyte. You weigh out
        <number-value :value="mass1" unit="\text{g}" />
        of the solid and dissolve it in
        <number-value :value="mass2" unit="\text{g}" />
        of water. Through experimentation, you find that the freezing point of the solution is
        <number-value :value="fpSoln" unit="^\circ\text{C.}" />
      </p>

      <p class="mb-3">a) What is the molality of the solution of the hypothetical solute?</p>

      <calculation-input
        v-model="inputs.molality"
        class="mb-5"
        prepend-text="$\text{Molality:}$"
        append-text="$m$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">b) How many moles of the hypothetical solute are in the solution?</p>

      <calculation-input
        v-model="inputs.mol"
        class="mb-5"
        prepend-text="$\text{Amount:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">c) What is the molar mass of the solute?</p>

      <calculation-input
        v-model="inputs.molarMass"
        prepend-text="$\text{Molar mass:}$"
        append-text="$\text{g/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question418',
  components: {
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        molality: null,
        mol: null,
        molarMass: null,
      },
    };
  },
  computed: {
    mass1() {
      return this.taskState.getValueBySymbol('mass1').content;
    },
    mass2() {
      return this.taskState.getValueBySymbol('mass2').content;
    },
    fpSoln() {
      return this.taskState.getValueBySymbol('fpSoln').content;
    },
  },
};
</script>
